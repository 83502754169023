/** @format */

import React from 'react';
import { PropTypes } from 'prop-types';
import { TileLayer, Marker, Circle } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useTranslation } from 'react-i18next';

import {
  credits,
  currentLocationMarkerIcon,
  tripMarkersConfig,
  defaultCenter,
  pickupMarkerIcon,
  signupOrgMarkerIcon,
  busStopMarkerIcon,
} from './config';

import TripPath from './tripPath';
import { MapRow, Map as SMap, BusStopTitle, BusesText, Tooltip } from './styled';

const serviceAreaRadius = 24140;

const Map = ({
  currentPosition,
  largeScreen,
  tripMarkers,
  organizationPlacesMapMarkers,
  tripPath,
  resizeMapKey,
  onOrganziationPlacesMapMarkerSelect,
  whenCreatedHandler,
  handleClusterClick,
  getCurrentLocation,
  signupOrganizationPlaces,
  isServiceAreaMap,
  busStopMarkers,
  onBusStopsMarkerSelect,
}) => {
  const { t } = useTranslation();
  const center =
    tripMarkers.pickup?.location ||
    tripMarkers.vehicle ||
    currentPosition ||
    defaultCenter;
  return (
    <>
      <MapRow justify="flex-start" align="flex-start">
        <SMap
          zoomControl
          largeScreen={largeScreen}
          center={center}
          zoom={isServiceAreaMap ? 9 : tripMarkers.pickup?.location ? 16 : 13}
          key={center}
          whenCreated={whenCreatedHandler}
        >
          <TileLayer attribution={credits.attribution} url={credits.url} />

          <TripPath tripPath={tripPath} resizeMapKey={resizeMapKey} fitBoundsOnPathUpdate={false} />

          {currentPosition && (
            <Marker
              id="current-location-marker"
              icon={currentLocationMarkerIcon}
              alt="Current Location Marker"
              position={currentPosition}
              title="Current Location"
            />
          )}

          {tripMarkersConfig.map(o => {
            if (!tripMarkers[o.key]) return null;
            return (
              <Marker
                key={o.key}
                id={`${o.key}-marker`}
                icon={o.icon}
                alt={o.alt}
                position={tripMarkers[o.key].location || tripMarkers[o.key]}
                title={t(o.title)}
              />
            );
          })}
          {/* for nearbyStops */}
          {busStopMarkers.map(stop => {
            return (
              <Marker
                key={stop.id}
                id={stop.id}
                icon={busStopMarkerIcon}
                alt={stop.label}
                position={{
                  lat: stop.lat,
                  lng: stop.lng,
                }}
                markerData={stop}
                eventHandlers={{
                  click: () => onBusStopsMarkerSelect(stop),
                }}
              >
                <Tooltip direction="top" opacity={1}>
                  <BusStopTitle>{stop.name}</BusStopTitle>
                  <BusesText>{stop.buses.join(', ')}</BusesText>
                </Tooltip>
              </Marker>
            );
          })}
          {/* Code for Org, Bus, Personal Addresses */}
          <MarkerClusterGroup
            showCoverageOnHover={false}
            onClick={handleClusterClick}
            zoomToBoundsOnClick={false}
          >
            {organizationPlacesMapMarkers?.map((place, key) => (
              <Marker
                key={key}
                id={`orgPlace-${key}`}
                icon={pickupMarkerIcon}
                position={[place?.lat, place?.lng]}
                eventHandlers={{
                  click: e => onOrganziationPlacesMapMarkerSelect(place),
                }}
                markerData={place}
              />
            ))}
            {isServiceAreaMap &&
              signupOrganizationPlaces?.map((place, key) => (
                <Marker
                  key={key}
                  id={`signup-orgPlace-${key}`}
                  icon={signupOrgMarkerIcon}
                  position={[place?.lat, place?.lng]}
                  markerData={place}
                />
              ))}
          </MarkerClusterGroup>

          {currentPosition && isServiceAreaMap && (
            <Circle radius={serviceAreaRadius} center={currentPosition} />
          )}
        </SMap>
      </MapRow>
    </>
  );
};

Map.propTypes = {
  currentPosition: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  largeScreen: PropTypes.bool.isRequired,
  tripMarkers: PropTypes.shape({
    pickup: PropTypes.array,
    vehicle: PropTypes.array,
    destination: PropTypes.array,
  }).isRequired,
  busStopMarkers: PropTypes.array,
  tripPath: PropTypes.shape({
    share: PropTypes.array,
    transit: PropTypes.array,
  }).isRequired,
  resizeMapKey: PropTypes.number.isRequired,
  onOrganziationPlacesMapMarkerSelect: PropTypes.func.isRequired,
  onBusStopsMarkerSelect: PropTypes.func.isRequired,
  whenCreatedHandler: PropTypes.func.isRequired,
  getCurrentLocation: PropTypes.func.isRequired,
};

Map.defaultProps = {
  currentPosition: null,
};

export default Map;
